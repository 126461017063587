import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Banner from "./banner"
import NewsList from "./newsList"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulPost(
          sort: { fields: [order, createdAt] order: [DESC, DESC] }
        ) {
          edges {
            node {
              id
              date
              slug
              title
              body {
                raw
              }
              createdAt
              covers {
                id
                title
                gatsbyImageData(layout: CONSTRAINED, width: 1000)
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Banner />
        <NewsList data={data.allContentfulPost.edges} />
      </>
    )}
  />
)
