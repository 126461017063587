import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { format } from "date-fns"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "./styles.scss"

export default ({ data }) => (
  <div className="news-container">
    <div className="news-body">
      {
        data.map(({ node }, index) => {
          if (index === 0) {
            return (
              <div className="items" key={node.id}>
                <div className="items-img">
                  {
                    node.covers.map(cover => (
                      <GatsbyImage
                        image={cover.gatsbyImageData}
                        key={cover.id}
                        alt={cover.title}
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                    ))
                  }
                </div>
                <div className="items-info">
                  <div className="title">{node.title}</div>
                  <div className="date">{format(new Date(node.date || node.createdAt), 'yyyy/MM/dd')}</div>
                  <p className="desc">{documentToPlainTextString(JSON.parse(node.body.raw)).replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '').substring(0, 200)}...</p>
                  <div className="more">
                    <Link to={`/news/${node.slug}`}>更多</Link>
                  </div>
                </div>
              </div>
            )  
          }
          return (
            <div className="items" key={node.id}>
              <div className="title">{node.title}</div>
              <div className="date">{format(new Date(node.date || node.createdAt), 'yyyy/MM/dd')}</div>
              <p className="desc">{documentToPlainTextString(JSON.parse(node.body.raw)).replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '').substring(0, 120)}...</p>
              <div className="more">
                <Link to={`/news/${node.slug}`}>更多</Link>
              </div>
            </div>
          )
        })
      }
    </div>
  </div>
)
