import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import NewsPage from "pages/news"

export const Head = () => (
  <SEO
    title="最新消息"
    description="衣庫國際持續與各領域廠商合作，口碑領先業界。與廠商伙伴密切合作，最新消息、優惠公告即時更新。"
    pathname="/news"
  />
)

export default () => (
  <Layout>
    <NewsPage />
  </Layout>
)
