import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default () => (
  <>
    <StaticImage
      className="global-hidden-mobile"
      src="../../../images/img-news-banner.png"
      alt="最新消息"
      layout="fullWidth"
      quality={100}
      breakpoints={[900, 1680, 3000]}
      placeholder="none"
      backgroundColor="#FFF"
    />
    <StaticImage
      className="global-hidden-desktop"
      src="../../../images/img-news-banner-m.png"
      alt="最新消息"
      layout="fullWidth"
      quality={100}
      breakpoints={[480, 1680]}
      placeholder="none"
      backgroundColor="#FFF"
    />
  </>
)
